import { Link } from "react-router-dom";

export default function HeroSection() {
  const openGitHub = (link) => {
    window.open(link, "_blank");
  };

  return (
    <section id="heroSection" className="hero--section">
      <div className="hero--section--content-box">
        <div className="hero--section--content">
          <p className="section--title">Hello, je suis ALESSI Thibaut</p>
          <h1 className="hero--section--title">
            <span className="hero--section--title--color">
              Etudiant à Polytech Lyon
            </span>{" "}
            <br />
            Passionné d'informatique
          </h1>
          <p className="hero--section--description">
            J'aime le C++, le SQL, la guitare, le sport et voyager.
            <br />
            Mon objectif, m'améliorer chaque jour.
          </p>
        </div>
      </div>
      <div className="hero--section--img">
        <img src="./img/moiherosection.jpg" alt="Hero Section" />
      </div>
    </section>
  );
}
