export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section">
      <div className="about--section--img">
        <img src="./img/moi.jpg" alt="AboutMe" />
      </div>
      <div className="hero--section--content-box about--section--box">
        <div className="hero--section--content">
          <p className="section--title">À propos</p>
          <h1 className="skills-section--heading">À propos de moi</h1>
          <p className="hero--section-description">
            Alternant en troisième année d'école d'ingénieur à Polytech Lyon en Informatique en alternance à WORLDLINE, occupant le poste d'ingénieur SRE/OPS.
          </p>
          <p className="hero--section-description">
            Passionné d’informatique, je suis à la recherche d’une mobilité à l'étranger pour développer mes compétences et contribuer à un projet d'entreprise qu’il soit orienté Infrastructure, Développement, OPS, Bases de Données ...
          </p>
        </div>
      </div>
    </section>
  );
}
