export default function ContactMe() {
  return (
    <section id="Contact" className="contact--section">
      <div>
        <p className="sub--title">Rentrer en Contact</p>
        <h2>Contactez Moi</h2>
        <p className="text-lg-with-margin">
          Si vous voulez me contacter, vous pouvez m'envoyer un mail à l'adresse
          email suivante :
        </p>
        <a href="mailto:thibaut.alessi@free.fr">thibaut.alessi@free.fr</a>
        <p className="text-lg">
          Ou bien directement par LinkedIn en bas de page.
        </p>
      </div>
    </section>
  );
}
